<template>
    <dx-util-popup
      ref="inventorySearchPopupRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :title="popupTitle"
      :full-screen="false"
      :width="resizingWidth"
      height="auto"
      :position="'center'"
      @shown="onShownInventorySearch"
      @hidden="onHiddenInventorySearch"
    >
      <div id="inventoryList">
        <div class="d-flex">
          <dx-util-text-box
            id="code-label-text"
            v-model.trim="searchBoxValue"
            mode="search"
            placeholder="Please enter search data (UPC, MSKU ...)"
            class="flex-grow-1 my-1"
            :on-enter-key="updateList"
          />
          <dx-util-number-box v-model="quantity" label="Quantity" class="w-25 my-1 ml-1" />
        </div>
        <dx-util-list
          id="availableInventoryList"
          ref="availableInventoryRef"
          height="60vh"
          :data-source="listDataSource"
          selection-mode="single"
          :bounce-enabled="true"
          :use-native-scrolling="true"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          :next-button-text="'Load More'"
          page-load-mode="nextButton"
          @item-click="listSelectionChanged"
        >
          <template #item="{ data }">
            <div>
              <div class="d-flex">
                <div class="">
                  <img :src="data.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 80px !important; margin-right: 0.625rem" class="rounded text-white">
                </div>
                <div class="container flex-grow-1">
                  <h5 class="text-wrap text-warning mb-half">
                    {{ data.title }}
                  </h5>
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <div>
                        <copy-button :copy-value="data.upc" />
                        <small class="text-primary">UPC: </small>
                        <span>{{ data.upc }}</span>
                      </div>
                      <div>
                        <copy-button :copy-value="data.asin" />
                        <small class="text-primary">ASIN: </small>
                        <span>{{ data.asin }}</span>
                      </div>
                      <div>
                        <copy-button :copy-value="data.fnsku" />
                        <small class="text-primary">FNSKU: </small>
                        <span>{{ data.fnsku }}</span>
                      </div>
                    </div>
                    <div class="w-50">
                      <div>
                        <copy-button :copy-value="data.msku" />
                        <small class="text-primary">MSKU: </small>
                        <span>{{ data.msku }}</span>
                      </div>
                      <div>
                        <copy-button :copy-value="data.palletId" />
                        <small class="text-primary">Exp Date: </small>
                        <span>{{ data.palletId }}</span>
                      </div>
                      <div>
                        <copy-button :copy-value="data.trackingNumber" />
                        <small class="text-primary">Tracking #: </small>
                        <span>{{ data.trackingNumber }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="text-center mx-half">
                    <small class="text-muted">
                      RESERVED
                    </small>
                    <h6 :class="`${data.reserved === 0 ? '' : 'text-warning'}`">
                      {{ data.reserved }}
                    </h6>
                  </div>
                  <div class="text-center mx-half">
                    <small class="text-muted">
                      SENT
                    </small>
                    <h6 :class="`${data.sentCount === 0 ? '' : 'text-primary'}`">
                      {{ data.sentCount }}
                    </h6>
                  </div>
                  <div class="text-center mx-half">
                    <small class="text-muted">
                      AVAILABLE
                    </small>
                    <h3 :class="`${data.quantity === 0 ? '' : 'text-success'}`">
                      {{ data.quantity }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </dx-util-list>

      </div>
      <div class="text-right">
        <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
        <dx-util-button text="Select Item" type="success" style="width:100px" @click="selectItem" />
      </div>
    </dx-util-popup>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import DataSource from 'devextreme/data/data_source'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import shipmentLabelService from '@/http/requests/external/shipmentLabels'
// import { v4 as uuidv4 } from 'uuid'
import { useWindowSize } from '@vueuse/core'
import { availableItemsStore } from './availableItemsStore'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    storeId: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { width: windowWidth } = useWindowSize()

    return {
      windowWidth,
    }
  },
  data() {
    return {
      popupTitle: '',
      selectedProduct: {},
      availableItemsStore,
      searchBoxValue: '',
      quantity: 1,
      listDataSource: new DataSource({
        store: availableItemsStore,
        paginate: true,
        pageSize: 20,
      }),
    }
  },
  computed: {
    inventorySearchPopup() {
      return this.$refs.inventorySearchPopupRef.instance
    },
    dataSource() {
      return this.$refs.availableInventoryRef.instance.getDataSource()
    },
    resizingWidth() {
      let adjustedWidth = ''
      if (this.windowWidth < 400) {
        adjustedWidth = '95vw'
      } if (this.windowWidth >= 400 && this.windowWidth <= 1200) {
        adjustedWidth = '80vw'
      } if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
        adjustedWidth = '60vw'
      } if (this.windowWidth > 1400) {
        adjustedWidth = '60vw'
      }
      return adjustedWidth
    },
  },
  watch: {
    componentId: {
      immediate: false,
      handler(newValue) {
        if (newValue) {
          this.searchBoxValue = ''
          this.inventorySearchPopup.show()
          this.getAvailableProducts()
        }
      },
    },
  },
  methods: {
    onShownInventorySearch() {
      this.popupTitle = 'Inventory Search'
    },
    onHiddenInventorySearch() {
      this.selectedProduct = {}
    },
    closePopup() {
      this.inventorySearchPopup.hide()
      this.selectedProduct = {}
    },
    async getAvailableProducts() {
      if (!this.storeId) {
        return
      }
      InventorySearchFilter.storeId = this.storeId
      InventorySearchFilter.q = ''

      const filters = InventorySearchFilter.getFilters()
      if (filters) {
        this.dataSource.searchValue(filters)
        this.dataSource.load()
      }
    },
    listSelectionChanged(e) {
      const item = e.itemData
      this.selectedProduct = item
    },
    updateList() {
      if (!this.storeId) {
        return
      }
      InventorySearchFilter.q = this.searchBoxValue
      const filters = InventorySearchFilter.getFilters()
      if (filters) {
        this.dataSource.searchValue(filters)
        this.dataSource.reload()
      }
    },
    async selectItem() {
      if (!this.selectedProduct?.id) {
        Notify.warning('Please select a product')
        return
      }

      const productDefaults = await shipmentLabelService.getProductDefaults(this.selectedProduct?.id)
      productDefaults.quantity = this.quantity
      this.$emit('emit-product-selected', productDefaults)
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" >
#inventoryList {
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: rgba(30, 41, 39, 0.2);
    color: rgb(226, 238, 236);
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
    background-color: rgba(27, 24, 39, 0.3);
  }
}
</style>
